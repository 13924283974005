.buzzer {
  height: 150px;
  width: 100%;
  order: 1;
  position: relative;
  background: #212121;
  border: none;
  color: #ffba18;
  padding: 15px 24px;
  font-size: 1.4rem;
  box-shadow: -6px 6px 0 #ffba18;
  outline: none;
  border: 0.2px solid #7c5a0b;
  cursor: pointer;
}

.buzzer:disabled {
  background: #212121;
  border: 0.2px solid gray;
  box-shadow: -6px 6px 0 gray;
  color: gray;
  cursor: inherit;
}

.buzzer:hover:not([disabled]) {
  background: #272727;
}

.buzzer:active:not([disabled]) {
  background: #212121;
  top: 3px;
  left: -3px;
  box-shadow: -3px 3px 0 #ffba18;
  color: white;
  text-shadow: 0px 0px 4px #ffba18, 0px 0px 20px #ffba18, 0px 0px 20px #ffba18,
    0px 0px 20px;
}

.buzzer::before:not([disabled]) {
  position: absolute;
  display: block;
  content: '';
  height: 0;
  width: 0;
  border: solid 6px transparent;
  border-right: solid 6px #ffba18;
  border-left-width: 0px;
  background: none;
  top: 0px;
  left: -6px;
}

.buzzer::after:not([disabled]) {
  position: absolute;
  display: block;
  content: '';
  height: 0;
  width: 0;
  border: solid 6px transparent;
  border-top: solid 6px #ffba18;
  border-bottom-width: 0px;
  background: none;
  right: 0;
  bottom: -6px;
}

.buzzer:active::before {
  border: solid 3px transparent;
  border-right: solid 3px #0db586;
  border-left-width: 0px;
  left: -3px;
}

.buzzer:active::after {
  border: solid 3px transparent;
  border-top: solid 3px #0db586;
  border-bottom-width: 0px;
  bottom: -3px;
}

.small-buzzer {
  height: 50px;
  width: 90%;
  order: 1;
  position: relative;
  background: #212121;
  border: none;
  color: #ffba18;
  padding: 15px 24px;
  font-size: 1rem;
  box-shadow: -6px 6px 0 #ffba18;
  outline: none;
  border: 0.2px solid #7c5a0b;
  cursor: pointer;
}
