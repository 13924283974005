.game-timer-grow {
  animation-name: grow;
  animation-duration: 0.25s;
  animation-direction: alternate;
  animation-iteration-count: 2;
}

@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

.lock-bar {
  display: flex;
  height: 31px;
  border-radius: 4px;
}

.lock-bar-right {
  flex-grow: 1;
  width: 247px;
}

.lock-bar-right-fill {
  height: 100%;
  transition: width ease-out 1s;
}

.dimmed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  z-index: 98; /* Ensure it's below the focused section */
}

.focused-section {
  position: relative; /* or absolute, depending on your layout needs */
  z-index: 99; /* Above the overlay */
  /* padding: 20px; */
  /* margin: 40px auto; */
  /* width: 50%; */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for 'lift' */
  transform: scale(1.05) translateY(-50px); /* Slightly scaled up */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scale */
}
